import { Context, Plugin } from '@segment/analytics-node'
import cookies from 'js-cookie'
import anonymizeObject from '../../utils/anonymizeObject'

const applyAnonymization = (ctx: Context, country) => {
  const { properties, context } = ctx.event
  const trackingConsented = cookies.get('trackingConsented') === 'true'
  const itemsToAnonymize = globalThis.itemsToAnonymize[country]

  if (!trackingConsented) {
    if (!itemsToAnonymize) {
      ctx.updateEvent('type', undefined)
      return ctx
    }
    const modifiedProperties = anonymizeObject(properties, itemsToAnonymize)
    const modifiedContext = {
      ...context,
      traits: {
        is_anonymized: true,
      },
    }
    ctx.updateEvent('properties', modifiedProperties)
    ctx.updateEvent('context', modifiedContext)
  }

  return ctx
}

const AnonymizationUtilityPlugin = (country, itemsToAnonymize): Plugin => ({
  name: 'AnonymizationUtility',
  version: '1.0.0',
  isLoaded: () => true,
  type: 'utility',
  load: (ctx) =>
    new Promise<Context>((resolve) => {
      globalThis.itemsToAnonymize = {
        ...globalThis.itemsToAnonymize,
        [country]: itemsToAnonymize,
      }

      resolve(ctx)
    }),
})

const AnonymizeTrackPlugin = (country): Plugin => ({
  name: 'AnonymizeTrack',
  version: '1.0.0',
  isLoaded: () => true,
  type: 'enrichment',
  load: () => Promise.resolve(),
  track: (ctx) => applyAnonymization(ctx, country),
  page: (ctx) => applyAnonymization(ctx, country),
})

export { AnonymizationUtilityPlugin, AnonymizeTrackPlugin }
