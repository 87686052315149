import { useState, useEffect } from 'react'
import { config } from '../../config'
import { UserInfos } from '../interfaces'

const enableLogging = process.env.STAGE !== 'prod'

const loadBraze = () => import('@braze/web-sdk')

const changeBrazeUser = (braze, userInfos) => {
  const { userId, email_address, phone_number } = userInfos

  braze.automaticallyShowInAppMessages()
  braze.changeUser(userId)
  braze.openSession()

  braze.getUser().setEmail(email_address)
  braze.getUser().setPhoneNumber(phone_number)
}

const setComplementaryData = (braze, userInfos) => {
  const { first_name, last_name, ...otherInfos } = userInfos
  const user = braze.getUser()
  user.setFirstName(first_name)
  user.setLastName(last_name)

  Object.entries(otherInfos).forEach(([k, v]) => {
    user.setCustomUserAttribute(k, v as string)
  })
}

interface UseBraze {
  userId: string
  country: string
  pathname: string
  trackingConsented?: boolean
  oneTrustInitialized?: boolean
  userInfos: UserInfos
}

const useBraze = ({
  userId,
  country,
  pathname,
  trackingConsented = false,
  oneTrustInitialized = false,
  userInfos: { requestTraceId, ...userInfos } = {},
}: UseBraze) => {
  const [brazeStarted, setBrazeStarted] = useState<boolean>(false)
  const [brazeInitialized, setBrazeInitialized] = useState<boolean>(false)
  const optOutInApp = config[country].braze?.optOutInApp ?? true

  useEffect(() => {
    if (
      pathname === '/' ||
      brazeStarted ||
      !oneTrustInitialized ||
      (!trackingConsented && !optOutInApp)
    )
      return

    if (process.env.STAGE !== 'prod') console.log('[useBraze] Initializing SDK')
    setBrazeStarted(true)
    const { key, host: baseUrl } = config[country].braze
    loadBraze().then((braze) => {
      window.braze = braze
      window.braze.initialize(key, {
        baseUrl,
        enableLogging,
        allowUserSuppliedJavascript: true,
      })
      changeBrazeUser(window.braze, { userId, ...userInfos })
      setBrazeInitialized(true)
    })
  }, [brazeStarted, oneTrustInitialized, userInfos, trackingConsented])

  useEffect(() => {
    if (
      typeof window !== undefined &&
      window.braze &&
      window.braze.getUser().getUserId() !== userId
    )
      changeBrazeUser(window.braze, { userId, ...userInfos, trackingConsented })
  }, [userId, userInfos, trackingConsented])

  useEffect(() => {
    if (oneTrustInitialized && brazeInitialized) {
      if (trackingConsented) setComplementaryData(window.braze, userInfos)
    }
  }, [oneTrustInitialized, brazeInitialized, trackingConsented, userInfos])

  return brazeInitialized
}

export default useBraze
